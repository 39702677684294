<template>
  <div id="footer">
    <footer class="pt-50 pb-50">
      <div class="container">
        <div class="row text-center">
          <div class="col-md-3 col-sm-6">
            <!--Contant Item-->
            <div class="contact-info">
              <h5>上海办公室</h5>
              <p>上海市黄浦区局门路427号<br />1号楼113室</p>
              <p>+86-21-3102-1498</p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <!--Contant Item-->
            <div class="contact-info">
              <h5>深圳办公室</h5>
              <p>深圳市南山区深南大道10128号<br />西塔1403室</p>
              <p>+86-755-8672-1512</p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <!--Contant Item-->
            <div class="contact-info">
              <h5>Email</h5>
              <p>inquiry@flarespeed.com</p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <!--Contant Item-->
            <div class="contact-info">
              <h5></h5>

            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-md-12">
            <hr>
            <p class="copy pt-30">
              &copy; 2019 - 2025 Flarespeed - 上海吉实信息技术有限公司<br />
              <a target="_blank" href="https://beian.miit.gov.cn/?utm_source=flarespeed&utm_medium=website&utm_campaign=null">沪ICP备20022124号-1</a>
              <br />电信增值业务许可证 A2.B1-20202457号
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer"
}
</script>

<style scoped>

</style>